














































































import { Component, Prop } from 'vue-property-decorator';
import ErrorMessageHandlerMixin from '../../misc/ErrorMessageHandler.mixin';
import { mixins } from 'vue-class-component';
import { email, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { namespace } from 'vuex-class';
import Promoter from '@/models/Promoter';
import { promoterStoreActions } from '@/store/promoter.store';

const PromoterStore = namespace('promoter');

@Component({
    mixins: [validationMixin],
    validations: {
        promoterCopy: {
            firstName: {
                required
            },
            lastName: {
                required
            },
            phone: {
                required
            },
            mail: {
                required,
                email
            }
        }
    }
})
export default class EditPromoterComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({ required: false, default: null })
  public promoter!: Promoter | null;

  @PromoterStore.Action(promoterStoreActions.CREATE)
  public createPromoterAction!: (promoter: Promoter) => Promise<Promoter>;

  @PromoterStore.Action(promoterStoreActions.UPDATE)
  public updatePromoterAction!: (manager: Promoter) => Promise<Promoter>;

  public promoterCopy: Promoter = new Promoter();
  public isLoading: boolean = false;
  private countryCode: string = '+264';

  private onSelect(payload: {dialCode: string, iso2: string, name: string}): void {
      this.countryCode = `+${payload.dialCode}`;
  }

  private created() {
      this.promoterCopy = Promoter.parseFromObject({ ...this.promoter });
  }

  public async saveData() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }

      if (this.promoter?.id) {
          await this.updatePromoter();
      } else {
          await this.createPromoter();
      }
  }

  private async createPromoter() {
      const promoterToUse = Promoter.parseFromObject({
          ...this.promoterCopy,
          phone: `${this.countryCode}${this.promoterCopy.phone}`
      });

      try {
          await this.createPromoterAction(promoterToUse);
          this.dismiss(true);
          this.$notifySuccessSimplified('PROMOTER.CREATE_SUCCESS');
      } catch (e) {
          this.$notifyErrorSimplified('PROMOTER.CREATE_ERROR');
      } finally {
          this.isLoading = false;
      }
  }

  private async updatePromoter() {
      const promoterToUse = Promoter.parseFromObject({
          ...this.promoterCopy,
          phone: `${this.countryCode}${this.promoterCopy.phone}`
      });

      try {
          await this.updatePromoterAction(promoterToUse);
          this.dismiss(true);
          this.$notifySuccessSimplified('PROMOTER.EDIT_SUCCESS');
      } catch (_) {
          this.$notifyErrorSimplified('PROMOTER.EDIT_ERROR');
      } finally {
          this.isLoading = false;
      }
  }

  public dismiss(reloadData: boolean = false) {
      this.$emit('closeDialog', reloadData);
  }
}

